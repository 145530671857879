<template>
  <v-row>
    <v-col cols="8">
      <v-row class="ml-15 mr-5">
        <v-col
          v-for="content in cartContents"
          :key="content.item.uuid"
          cols="12"
        >
          <v-card
            v-if="!content.item.deletedAt"
            class="outset welfare-card mb-5 px-5 py-5"
          >
            <v-row>
              <v-col cols="4">
                <a
                  @click="showDetail(content.item.uuid)"
                >
                  <v-img
                    v-if="content.item.mainImgSrc"
                    :src="content.item.mainImgSrc"
                    class="small-outset"
                    height="200"
                    width="200"
                  />
                </a>
              </v-col>
              <v-col cols="8">
                <a
                  @click="showDetail(content.item.uuid)"
                >
                  <v-card-title v-text="content.item.name" />
                </a>
                <v-card-subtitle>
                  {{ content.item.price.toLocaleString() }} pt
                </v-card-subtitle>
                <v-card-text
                  v-if="content.item.expirationPeriod"
                >
                  有効期限：交換から {{ content.item.expirationPeriod }} 日間
                </v-card-text>
                <v-card-text
                  v-else
                >
                  有効期限：なし
                </v-card-text>
                <v-select
                  v-model="content.count"
                  class="ml-4"
                  :items="countSelectItems"
                  dense
                  label="数量"
                  outlined
                  style="width: 100px;"
                />
                <v-textarea
                  v-model="content.userNote"
                  class="ml-4"
                  outlined
                  label="備考"
                  hint="担当者への連絡事項など"
                />
                <v-card-text
                  v-if="content.item.precaution"
                  style="white-space: pre-wrap;"
                >
                  <p class="warning--text font-weight-bold mb-0">
                    <v-icon class="warning--text">
                      mdi-alert
                    </v-icon>
                    注意事項
                  </p>
                  {{ content.item.precaution }}<br>
                  <v-icon color="info">
                    mdi-checkbox-marked-circle-outline
                  </v-icon>
                  確認済み
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn
                    rounded
                    outlined
                    color="error"
                    class="small-outset mr-5 mb-5 px-5"
                    @click="removeFromCart(content.item.uuid)"
                  >
                    <v-icon class="mr-2">
                      mdi-trash-can
                    </v-icon>
                    カートから削除
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>

          <v-card
            v-else
            class="outset welfare-disabled-card mb-5 px-5 py-5"
          >
            <v-row>
              <v-col cols="4" />
              <v-col cols="8">
                <v-card-title v-text="content.item.name" />
                <v-card-subtitle>
                  {{ content.item.price.toLocaleString() }} pt
                </v-card-subtitle>
                <v-card-text>
                  このアイテムは削除されているためポイント交換できません
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn
                    rounded
                    color="error"
                    class="mr-5 mb-5 px-5"
                    @click="removeFromCart(content.item.uuid)"
                  >
                    <v-icon class="mr-2">
                      mdi-trash-can
                    </v-icon>
                    カートから削除
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col
          v-if="cartContents.length === 0"
        >
          <v-icon large>
            mdi-briefcase-off
          </v-icon>
          カートにアイテムがありません
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="4">
      <v-card class="inset welfare-card ml-5 mr-15 pa-3">
        <v-card-title>
          カート内の小計
        </v-card-title>
        <v-card-subtitle>{{ totalPoints.toLocaleString() }} pt</v-card-subtitle>

        <v-card-text>
          <div>
            現在の所持ポイント数： {{ currentPoints.toLocaleString() }} pt
          </div>
          <div>
            交換後のポイント数： {{ afterExchangePoints.toLocaleString() }} pt
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <confirm-button
            button-text="ポイント交換"
            button-color="primary"
            button-icon="mdi-hand-coin"
            button-width="180"
            :button-disabled="isDisableToExchange"
            dialog-title="ポイント交換"
            dialog-body="カート内アイテムのポイント交換を行います。
            よろしいですか？"
            @ok-action="exchange"
          />
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ConfirmButton from '@/components/common/ConfirmButton'

export default {
  components: {
    ConfirmButton
  },

  props: {
    cartInfo: {
      type: Object,
      default: () => {}
    },
    totalPoints: {
      type: Number,
      default: 0
    },
    currentPoints: {
      type: Number,
      default: 0
    },
    afterExchangePoints: {
      type: Number,
      default: 0
    },
    isDisableToExchange: {
      type: Boolean,
      default: true
    },
    countSelectItems: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    cartContents () {
      return this.cartInfo.contents ? this.cartInfo.contents : []
    }
  },

  methods: {
    showDetail (uuid) {
      this.$emit('showDetail', this, uuid)
    },
    removeFromCart (uuid) {
      this.$emit('removeFromCart', this, uuid)
    },
    exchange () {
      this.$emit('exchange', this)
    }
  }
}
</script>
