import Presenter from '@/components/pages/user/cart/detail/Presenter'
import { mapGetters } from 'vuex'
import constants from '@/constants.js'
import store from '@/store'

export default {
  computed: {
    ...mapGetters([
      'cartInfo',
      'userInfo'
    ]),
    // カート内のアイテムポイント数合計
    totalPoints () {
      var cnt = 0
      const cartContents = this.cartInfo.contents
      if (!cartContents) {
        return cnt
      }
      for (const content of cartContents) {
        cnt += content.item.price * content.count
      }
      return cnt
    },
    // 現在のユーザーの手持ちポイント数
    currentPoints () {
      return this.userInfo.point
    },
    // ポイント交換後のポイント数
    afterExchangePoints () {
      return this.currentPoints - this.totalPoints
    },
    // ポイント交換可否
    isDisableToExchange () {
      return this.afterExchangePoints < 0 || !this.cartInfo.contents
    }
  },

  data: () => ({
    countSelectItems: []
  }),

  created () {
    const selectItems = []
    Array(constants.MAX_COUNT_PER_ITEM).fill().forEach((_, i) => selectItems.push(i + 1))
    this.countSelectItems = selectItems
  },

  methods: {
    getTotalPoints () {
      var cnt = 0
      for (const content of this.cart.contents) {
        cnt += content.item.price * content.count
      }
      return cnt
    }
  },

  render (h) {
    return h(Presenter, {
      props: {
        cartInfo: this.cartInfo,
        totalPoints: this.totalPoints,
        currentPoints: this.currentPoints,
        afterExchangePoints: this.afterExchangePoints,
        isDisableToExchange: this.isDisableToExchange,
        countSelectItems: this.countSelectItems
      },

      on: {
        showDetail (presenter, itemUuid) {
          presenter.$router.push({ name: 'ItemDetail', params: { uuid: itemUuid }})
        },
        async removeFromCart (presenter, itemUuid) {
          const cartInfo = await presenter.$store.dispatch('cart/removeItem', itemUuid)
          presenter.$store.commit('updateCartInfo', cartInfo)
          presenter.$store.commit('message/show', {
            message: 'アイテムをカートから削除しました。',
            color: 'info',
            timeout: 4000
          })
        },
        async exchange (presenter) {
          store.commit('updateLoading', { flag: true })
          const cartInfo = presenter.cartInfo
          const data = {
            'cartUuid': cartInfo.uuid,
            'contents': cartInfo.contents
          }
          const orderNumber = await presenter.$store.dispatch('cart/exchange', data)
          const user = await presenter.$store.dispatch('cart/getUser')
          presenter.$store.commit('updateUserInfo', user)
          presenter.$store.commit('clearCartInfo', cartInfo)
          presenter.$router.push({ name: 'ExchangeComplete', params: { orderNumber: orderNumber }}, () => {})
        }
      }
    })
  }
}
